import React from 'react';
import PropTypes from 'prop-types';

import * as S from './Quote.styles';

const Quote = ({ quote, author }) => (
  <S.StyledQuote>
    <S.QuoteIcon>
      <S.StyledIcon name="quote-left" />
    </S.QuoteIcon>
    {quote && (
      <S.QuoteContainer>
        <S.Content>{`${quote}`}</S.Content>
      </S.QuoteContainer>
    )}
    {author && (
      <S.AuthorContainer>
        <S.Author>{author}</S.Author>
      </S.AuthorContainer>
    )}
  </S.StyledQuote>
);

Quote.propTypes = {
  /**
   Content of the author part containing a short description.  
   */
  author: PropTypes.string.isRequired,
  /**
   Content of the quote part.
   */
  quote: PropTypes.string.isRequired,
};

export default Quote;
