import styled from 'styled-components';
import Icon from 'uikit/Icon';

import { Color, MediaQuery } from 'wooclap/utils/constants';

export const QuoteContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;

  p {
    margin: 0;
  }
`;

export const AuthorContainer = styled.div`
  width: 100%;

  p {
    margin: 0;
  }
`;

export const StyledQuote = styled.div`
  background-color: ${Color.WHITE};
  max-width: 900px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  margin: 0px auto 50px auto;
  padding: 30px;
  box-shadow: 0 30px 120px 0 rgba(135, 141, 238, 0.15);
  text-align: center;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    margin: 20px;
  }

  @media ${MediaQuery.MOBILE} {
    padding: 20px 10px 20px 10px;
    margin: 20px;
  }
`;

export const Content = styled.p`
  font-size: 24px;
  font-style: italic;
  color: ${Color.CLOUD_BURST};
`;

export const Author = styled.span`
  font-size: 16px;
  color: #656686;
`;

export const QuoteIcon = styled.div`
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875em;
  border-radius: 10px;
`;

export const StyledIcon = styled(Icon)`
  background-color: #8258f3;
  background-image: linear-gradient(45deg, #8258f3, #9f7cff);
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  padding: 2px 1px 0 0;
`;
