import classNames from 'classnames/bind';
import React from 'react';
import PropType from 'prop-types';

import HeaderImage from 'assets/images/landing-ai/bus.jpg';
import FlashcardImage from 'assets/images/landing-ai/flashcard.jpg';
import BigCTAImage from 'assets/images/landing-ai/bigcta.jpg';

import BigCta from 'wooclap/components/page-sections/big-cta';
import Button from 'uikit/Button';
import Icon from 'uikit/Icon';
import Layout from 'components/Layout';
import Quote from 'wooclap/components/Quote/Quote';

import styles from './LandingAI.module.scss';

const cx = classNames.bind(styles);

const LandingAI = ({ pageContext: { lang, layout, content, urls } }) => {
  const picture = {
    url: BigCTAImage,
  };

  return (
    <Layout lang={lang} t={layout} urls={urls}>
      <main className={cx('main')}>
        <section className={cx('header')}>
          <div className={cx('text-bloc')}>
            <h1 className={cx('header-title')}>
              {content.bloc1Title}
              <strong>{content.bloc1TitleStrong}</strong>
            </h1>
            <p className={cx('header-paragraph')}>{content.bloc1Text1}</p>
            <ul className={cx('header-list')}>
              <li className={cx('header-item')}>
                {content.bloc1Text2}
                <strong>{content.bloc1Text2Strong}</strong>
              </li>
              <li className={cx('header-item')}>
                <strong>{content.bloc1Text3Strong}</strong>
                {content.bloc1Text3}
              </li>
            </ul>
            <p className={cx('header-last-paragraph')}>
              {content.bloc1Text4}
              <strong>{content.bloc1Text4Strong}</strong>
              {content.bloc1Text4Part2}
            </p>
            <div className={cx('actions')}>
              <Button
                as="a"
                className={cx('cta-button')}
                href={content.CTA.link}
                intent="primary"
                target="_blank"
                size="large"
              >
                {content.CTA.label}
              </Button>
            </div>
          </div>
          <img alt="" className={cx('header-illustration')} src={HeaderImage} />
        </section>
        <section className={cx('flashcard')}>
          <img alt="" className={cx('flashcard-image')} src={FlashcardImage} />
          <div className={cx('flashcard-text-container')}>
            <h2 className={cx('flashcard-title')}>
              {content.flashcardSection.title}
            </h2>
            <p className={cx('flashcard-subtitle')}>
              <strong>{content.flashcardSection.subtitlePart1Strong}</strong>
              {content.flashcardSection.subtitlePart1}
              <strong>{content.flashcardSection.subtitlePart2Strong}</strong>
              {content.flashcardSection.subtitlePart2}
              <strong>{content.flashcardSection.subtitlePart3Strong}</strong>
              {content.flashcardSection.subtitlePart3}
            </p>
            <Button
              as="a"
              className={cx('flashcard-cta')}
              href={content.flashcardSection.link}
              target="_blank"
              intent="primary"
              size="large"
            >
              {content.flashcardSection.cta}
            </Button>
          </div>
        </section>
        <section>
          <h2 className={cx('cards-title')}>{content.bloc2Title}</h2>
          <p className={cx('cards-subtitle')}>{content.bloc2Subtitle}</p>
          <div className={cx('cards')}>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="alarm-clock" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part1Title}</h3>
              <p className={cx('paragraph')}>
                <strong>{content.bloc2Part1TextStrong}</strong>
                {content.bloc2Part1Text}
              </p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="repeat" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part2Title}</h3>
              <p className={cx('paragraph')}>
                <strong>{content.bloc2Part2TextStrong}</strong>
                {content.bloc2Part2Text}
              </p>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="brain" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part3Title}</h3>
              <p className={cx('paragraph')}>
                <strong>{content.bloc2Part3TextStrong}</strong>
                {content.bloc2Part3Text}
              </p>
            </div>
          </div>
        </section>
        <section>
          <Quote quote={content.quote.quote} author={content.quote.author} />
        </section>
        <section>
          <BigCta
            title={content.bigCTA.title}
            intro={content.bigCTA.intro}
            theme={content.bigCTA.theme}
            link={content.bigCTA.link}
            picture={picture}
          />
        </section>
      </main>
    </Layout>
  );
};

LandingAI.propTypes = {
  pageContext: PropType.object.isRequired,
};

export default LandingAI;
